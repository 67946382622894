import React, { Fragment, useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Box, Divider, FormControlLabel, FormHelperText, InputAdornment, RadioGroup, Radio, Slider, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { prices, calculateImageDiscount, calculateClassPrices, calculateAccuracyPrice } from './data/prices';
import { wages } from './data/wages';
import { categoricalWages } from './data/categoricalWages';
import ManthanoLogo from '../../media/MathanoLogo';

const useStyles = makeStyles(theme => ({
    radioButton: {
        justifyContent: 'center'
    },
    divider: {
        marginTop: 10,
        marginBottom: 10,
    },
    helperText: {
        justifyContent: 'center',
        textAlign: 'center',
        paddingBottom: 10,
        paddingInline: '10%'
    },
    countrySelection: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    slider: {
        marginInline: '15%'
    }
}))


export default function CalculatorView(props) {

    const [projectInfo, setProjectInfo] = useState({
        annotationData: 'image',
        imageAmount: 1000,
        annotationType: 'Classification',
        complexityOfAnnotation: 'middle',
        numberOfClasses: 2,
        averageAnnotationsPerImage: 3,
        accuracyMetric: 90,
        hourlyWage: 6,
        selectedCountry: null,
        framesPerSecond: 30,
        durationOfVideo: 10,
    })
    const classes = useStyles()

    useEffect(() => {
        const valueToChange = projectInfo.annotationData === 'image' ? 1000 : 10
        handleChange(valueToChange, 'imageAmount')
    }, [projectInfo.annotationData])

    function handleChange(value, key) {
        var obj = {}
        obj[key] = value
        setProjectInfo({ ...projectInfo, ...obj })
    }

    function valueText(value) {
        return `${value}`
    }

    function percentageText(value) {
        return `${value} %`
    }

    function styledText(regularText, fatText) {
        return <div>
            <Typography style={{ display: 'inline-block' }} inline variant='h6'>{regularText}</Typography>
            <Typography style={{ display: 'inline-block' }} inline variant='h6'>
                <Box fontWeight="fontWeightBold" m={0.5}>
                    {fatText}
                </Box>
            </Typography>
        </div>
    }

    function videoSpecs() {
        return <div
            style={{ marginInline: '20%', justifyContent: 'center', flexDirection: 'row' }}>
            <Typography variant="h6" gutterBottom>
                Frames Per Second: {projectInfo.framesPerSecond}
            </Typography>
            <FormHelperText className={classes.helperText}>Define the framerate of the video</FormHelperText>
            <div className={classes.slider}>
                <Slider
                    defaultValue={30}
                    getAriaValueText={valueText}
                    aria-labelledby="discrete-slider"
                    step={1}
                    min={10}
                    max={100}
                    onChange={(event, value) => { handleChange(value, 'framesPerSecond') }}
                />
            </div>
            <Divider orientation='horizontal' className={classes.divider} />
            <Typography variant="h6" gutterBottom>
                Average duration of one video sequence: {projectInfo.durationOfVideo} second{projectInfo.durationOfVideo > 1 ? 's' : null}
            </Typography>
            <FormHelperText className={classes.helperText}>Define the average duration of the video</FormHelperText>
            <div className={classes.slider}>
                <Slider
                    defaultValue={20}
                    getAriaValueText={valueText}
                    aria-labelledby="discrete-slider"
                    step={1}
                    min={1}
                    max={100}
                    onChange={(event, value) => { handleChange(value, 'durationOfVideo') }}
                />
            </div>
            <Divider orientation='horizontal' className={classes.divider} />
        </div >
    }

    function imageOrVideo() {
        const textValue = projectInfo.annotationData === 'image' ? 'image' : 'video'
        return textValue
    }

    function imageOrFrame() {
        const textValue = projectInfo.annotationData === 'image' ? 'image' : 'frame'
        return textValue
    }

    function calculateFunction() {
        // Get all the information from the projectInfo object
        var { annotationData, imageAmount, annotationType, complexityOfAnnotation, numberOfClasses,
            averageAnnotationsPerImage, accuracyMetric, hourlyWage, framesPerSecond, durationOfVideo } = projectInfo
        var complexityMapping = {
            'easy': 0.9,
            'middle': 1.0,
            'hard': 1.2
        }
        var complexityMarkup = complexityMapping[complexityOfAnnotation]
        imageAmount = (annotationData === 'image') ? imageAmount : imageAmount * framesPerSecond * durationOfVideo 
        var annotationTypeTime = prices[annotationType]
        var classSelectionTime = calculateClassPrices(numberOfClasses)
        var secondlyWage = hourlyWage / 3600
        var pricePerAnnotation = secondlyWage * annotationTypeTime
        var pricePerSelection = secondlyWage * classSelectionTime
        var accuracyMarkup = calculateAccuracyPrice(accuracyMetric)
        var platformMarkup = 1.5
        var quantityDiscount = calculateImageDiscount(imageAmount)
        var pricePerImage = (pricePerAnnotation + pricePerSelection) * averageAnnotationsPerImage  * complexityMarkup
        var pricePerImageIncludingMarkups = (pricePerImage * accuracyMarkup * platformMarkup * quantityDiscount).toFixed(5)
        var pricePerProject = (pricePerImageIncludingMarkups * imageAmount).toFixed(0)
        return <Typography style={{ whiteSpace: "pre-line" }} border={10} className={classes.helperText} inline variant="h6" >
            {styledText(`${imageOrFrame()}s to annotate:`, `${imageAmount}`)}
            {styledText(`annotation data type:`, `${annotationData}`)}
            {styledText(`time per annotation:`, `${annotationTypeTime} s`)}
            {styledText(`complexity of annotation:`, `${complexityOfAnnotation}`)}
            {styledText(`base price per ${imageOrFrame()}:`, `${pricePerImage.toFixed(5)} $ / image`)}
            {styledText(`---`, ``)}
            {styledText(`accuracy markup factor:`, `${accuracyMarkup}`)}
            {styledText(`platform markup factor (margin):`, `~50%`)}
            {styledText(`quantity discount:`, `${((1 - quantityDiscount) * 100).toFixed(1)} %`)}
            {styledText(`------`, ``)}
            {styledText(`price per ${imageOrFrame()} including markups:`, `${pricePerImageIncludingMarkups} $ / image`)}
            {styledText(`approximate project price:`, `${pricePerProject} $`)}

        </Typography>
    }

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="md">
                <Grid xs='auto' style={{ justifyContent: 'center' }} item>
                    <ManthanoLogo style={{ width: '50%', paddingBlockStart: 40 }}></ManthanoLogo>
                    <Typography variant="h3" gutterBottom style={{ paddingBlockStart: 60 }}>Project Cost Calculator</Typography>
                    <Typography variant="h5" gutterBottom style={{ paddingBlockEnd: 10 }}>Computer Vision Applications</Typography>
                    <FormHelperText className={classes.helperText} style={{ paddingBlockEnd: 30 }}>
                        Since it is always difficult to define the project costs for an annotation project,
                        Manthano would like to provide a calculator where you can freely choose your parameters and take a look behind the scenes at the annotation platform prices.
                        It will give you a better feeling for the market and show you which parameters are most important
                    </FormHelperText>
                    <Typography variant="h6" gutterBottom>
                        Define the input data type
                    </Typography>
                    <RadioGroup
                        row
                        className={classes.radioButton}
                        aria-label="annotationData"
                        name="annotationData"
                        value={projectInfo.annotationData}
                        onChange={(event, value) => { handleChange(value, 'annotationData') }}>
                        <FormControlLabel value="image" control={<Radio />} label="image" />
                        <FormControlLabel value="video" control={<Radio />} label="video" />
                    </RadioGroup>
                    <Divider orientation='horizontal' className={classes.divider} />
                    <Typography variant="h6" gutterBottom>
                        Amount of {projectInfo.annotationData}s: {projectInfo.imageAmount}
                    </Typography>
                    <div className={classes.slider}>
                        {projectInfo.annotationData === 'image' ? (
                            <Slider
                                defaultValue={1000}
                                getAriaValueText={valueText}
                                aria-labelledby="discrete-slider"
                                step={1000}
                                min={1000}
                                max={100000}
                                onChange={(event, value) => { handleChange(value, 'imageAmount') }}
                            />
                        ) : (
                            <Slider
                                defaultValue={10}
                                getAriaValueText={valueText}
                                aria-labelledby="discrete-slider"
                                step={10}
                                min={10}
                                max={1000}
                                onChange={(event, value) => { handleChange(value, 'imageAmount') }}
                            />)}

                    </div>
                    <Divider orientation='horizontal' className={classes.divider} />
                    {projectInfo.annotationData === 'video' ? videoSpecs() : null}
                    <Typography variant="h6" gutterBottom>
                        Define the annotation type
                    </Typography>
                    <Grid xs={12} item>
                        <RadioGroup
                            className={classes.radioButton}
                            row
                            aria-label="gender"
                            name="gender1"
                            value={projectInfo.annotationType}
                            onChange={(event, value) => { handleChange(value, 'annotationType') }}>
                            <FormControlLabel value="Classification" control={<Radio />} label="Classification" />
                            <FormControlLabel value="Point Annotation" control={<Radio />} label="Point Annotation" />
                            <FormControlLabel value="Line Annotation" control={<Radio />} label="Line Annotation" />
                            <FormControlLabel value="2D Box Annotation" control={<Radio />} label="2D Box Annotation" />
                            <FormControlLabel value="3D Box Annotation" control={<Radio />} label="3D Box Annotation" />
                            <FormControlLabel value="Polygon Annotation" control={<Radio />} label="Polygon Annotation" />
                            <FormControlLabel value="Lidar 3D Box Annotation" control={<Radio />} label="Lidar 3D Box Annotation" />
                            <FormControlLabel value="Lidar Pixelwise Annotation" control={<Radio />} label="Lidar Pixelwise Annotation" />
                        </RadioGroup>
                    </Grid>
                    <Divider orientation='horizontal' className={classes.divider} />
                    <Typography variant="h6" gutterBottom>
                        Number of Classes: {projectInfo.numberOfClasses}
                    </Typography>
                    <FormHelperText className={classes.helperText}>Define the number of classes, e.g. pedestrian, road, car, tree = 4</FormHelperText>
                    <div className={classes.slider}>
                        <Slider
                            defaultValue={2}
                            getAriaValueText={valueText}
                            aria-labelledby="class-slider"
                            step={1}
                            scale={(x) => x}
                            min={2}
                            max={100}
                            onChange={(event, value) => { handleChange(value, 'numberOfClasses') }}
                        />
                    </div>
                    <Divider orientation='horizontal' className={classes.divider} />
                    <Typography variant="h6" gutterBottom>
                        Complexity of annotations
                    </Typography>
                    <FormHelperText className={classes.helperText}> Define the complexity of your annotations, e.g. annotating apples = easy, annotating different nuts = middle, annotation complex foods, if you are unsure keep middle </FormHelperText>
                    <RadioGroup
                        row
                        className={classes.radioButton}
                        aria-label="complexityOfAnnotation"
                        name="complexityOfAnnotation"
                        value={projectInfo.complexityOfAnnotation}
                        onChange={(event, value) => { handleChange(value, 'complexityOfAnnotation') }}>
                        <FormControlLabel value="easy" control={<Radio />} label="easy" />
                        <FormControlLabel value="middle" control={<Radio />} label="middle" />
                        <FormControlLabel value="hard" control={<Radio />} label="hard" />
                    </RadioGroup>
                    <Divider orientation='horizontal' className={classes.divider} />
                    <Typography variant="h6" gutterBottom>
                        Average annotations per {imageOrVideo()}: {projectInfo.averageAnnotationsPerImage}
                    </Typography>
                    <FormHelperText className={classes.helperText}>Define how many annotations are need to be done per {imageOrVideo()}, e.g. annotate 4 apples per {imageOrVideo()}</FormHelperText>
                    <div className={classes.slider}>
                        <Slider
                            defaultValue={2}
                            getAriaValueText={valueText}
                            aria-labelledby="averageAnnotationsPerImage"
                            step={1}
                            min={2}
                            max={100}
                            onChange={(event, value) => { handleChange(value, 'averageAnnotationsPerImage') }}
                        />
                    </div>
                    <Divider orientation='horizontal' className={classes.divider} />
                    <Typography variant="h6" gutterBottom>
                        Define the accuracy of the annotations: {projectInfo.accuracyMetric} %+ IoU (Intersection of Union)
                    </Typography>
                    <FormHelperText className={classes.helperText}>Define how accurate the annotations need to be done, the IoU is the intersection plane which overlaps from the annotation and the reality</FormHelperText>
                    <div className={classes.slider}>
                        <Slider
                            defaultValue={90}
                            getAriaValueText={percentageText}
                            aria-labelledby="accuracyMetric"
                            step={1}
                            min={80}
                            max={99}
                            onChange={(event, value) => { handleChange(value, 'accuracyMetric') }}
                        />
                    </div>
                    <Divider orientation='horizontal' className={classes.divider} />
                    <Typography variant="h6" gutterBottom>
                        Select the price range in which your annotators work (approximated wages)
                    </Typography>
                    <FormHelperText className={classes.helperText} style={{ paddingBottom: 30 }}>
                        Select in which category you assume your annotators work, you can also choose your own hourly wage rate,
                        note that the wages are just an approximation, if you are unsure choose low-medium earnings</FormHelperText>
                    <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Grid xs={'auto'} style={{ paddingRight: 20 }} className={classes.countrySelection}>
                            <Autocomplete

                                id="wage selection"
                                options={Object.keys(categoricalWages)}
                                getOptionLabel={(option) => option}
                                style={{ width: 300 }}
                                onChange={(event, value) => { handleChange(value, 'selectedCountry'); handleChange(categoricalWages[value], 'hourlyWage') }}
                                renderInput={(params) =>
                                    <TextField
                                        label="choose annotator earnings"
                                        variant="outlined"
                                        {...params} />}
                            />
                        </Grid>
                        <Grid className={classes.countrySelection} xs={"auto"}>
                            <TextField
                                row label={'hourly rate'}
                                variant='outlined'
                                onChange={(event, value) => { handleChange(event.target.value, 'hourlyWage') }}
                                value={projectInfo.hourlyWage}
                                InputProps={{ endAdornment: <InputAdornment position="end">$/h</InputAdornment> }}
                                InputLabelProps={{ shrink: true }}></TextField>
                        </Grid>
                    </Grid>
                    <Divider orientation='horizontal' className={classes.divider} />
                    <Typography inline variant="h2" style={{ padding: 10, paddingBlockEnd: 20 }}>
                        Summary
                    </Typography>
                    <Box border={2} style={{ marginInline: '15%', paddingBlock: 30, marginBottom: 30 }} borderColor={"#5b69c4"} borderRadius={20} boxShadow={10}>
                        {calculateFunction()}
                        <ManthanoLogo style={{ width: '40%', paddingBlockStart: 40 }}></ManthanoLogo>
                        <FormHelperText className={classes.helperText} inline style={{ marginInline: '15%', paddingBlockStart: 10 }}>Manthano is exempt from any liability</FormHelperText>
                    </Box>
                </Grid>
            </Container>
        </Fragment >
    )
}
CalculatorView.props = {
}