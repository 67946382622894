import React from 'react';
import logo from './Logo_Manthano_GmbH_HTML.svg';

export default function ManthanoLogo(props) {
    var Logo =
        <a href="https://www.manthano.ai">
            <div align="center" >
                <img {...props} src={logo} alt="logo"></img>
            </div >
        </a>
    return Logo
}