const wages = {
    "Georgia": 0.15,
    "Cuba": 0.2,
    "Bangladesh": 0.225,
    "Tanzania": 0.225,
    "Kyrgyzstan": 0.25,
    "Malawi": 0.3,
    "Democratic Republic of the Congo": 0.325,
    "Liberia": 0.325,
    "The Gambia": 0.325,
    "Guinea-Bissau": 0.425,
    "Tajikistan": 0.425,
    "Uganda": 0.525,
    "Madagascar": 0.55,
    "Mali": 0.6,
    "Mozambique": 0.675,
    "Ghana": 0.7,
    "India": 0.7,
    "Kenya": 0.7,
    "Botswana": 0.725,
    "Niger": 0.75,
    "Senegal": 0.775,
    "Bhutan": 0.825,
    "Eswatini": 0.825,
    "Myanmar": 0.825,
    "Burkina Faso": 0.85,
    "Sri Lanka": 0.85,
    "Togo": 0.875,
    "Cameroon": 0.9,
    "Côte d'Ivoire": 0.9,
    "Central African Republic": 0.95,
    "Dominican Republic": 0.95,
    "Benin": 1,
    "Syria": 1,
    "Solomon Islands": 1.025,
    "Tunisia": 1.025,
    "Lesotho": 1.125,
    "Mauritania": 1.2,
    "Afghanistan": 1.25,
    "Kazakhstan": 1.25,
    "Zambia": 1.25,
    "Nicaragua": 1.275,
    "Haiti": 1.425,
    "Nigeria": 1.425,
    "Sierra Leone": 1.425,
    "Timor-Leste": 1.5,
    "Chad": 1.525,
    "Nepal": 1.55,
    "Pakistan": 1.725,
    "Cape Verde": 1.75,
    "Iran": 1.775,
    "Angola": 1.825,
    "Comoros": 1.825,
    "Vietnam": 1.825,
    "Samoa": 1.975,
    "Armenia": 2.025,
    "Laos": 2.025,
    "Azerbaijan": 2.1,
    "Moldova": 2.175,
    "Turkmenistan": 2.2,
    "Republic of the Congo": 2.225,
    "China": 2.35,
    "Mexico": 2.375,
    "Kuwait": 2.4,
    "Guyana": 2.45,
    "Belarus": 2.475,
    "Mongolia": 2.475,
    "Kiribati": 2.5,
    "Papua New Guinea": 2.5,
    "Ukraine": 2.55,
    "Malaysia": 2.575,
    "Algeria": 2.6,
    "Equatorial Guinea": 2.675,
    "Honduras": 2.7,
    "North Macedonia": 2.725,
    "Albania": 2.9,
    "Kosovo": 2.9,
    "Saint Vincent and the Grenadines": 2.9,
    "Russia": 3,
    "Iraq": 3.05,
    "Thailand": 3.05,
    "Montenegro": 3.125,
    "Indonesia": 3.15,
    "Fiji": 3.225,
    "Mauritius": 3.275,
    "Philippines": 3.325,
    "Bosnia and Herzegovina": 3.375,
    "Jamaica": 3.425,
    "Peru": 3.425,
    "Uzbekistan": 3.575,
    "Dominica": 3.7,
    "Gabon": 3.725,
    "South Africa": 3.75,
    "Bolivia": 3.775,
    "Brazil": 3.825,
    "Panama": 3.825,
    "El Salvador": 4,
    "Serbia": 4,
    "Vanuatu": 4,
    "Morocco": 4.05,
    "Belize": 4.125,
    "Guatemala": 4.125,
    "Colombia": 4.325,
    "Paraguay": 4.525,
    "Libya": 4.675,
    "Seychelles": 4.9,
    "Marshall Islands": 5,
    "Palestine": 5.175,
    "Bulgaria": 5.275,
    "Lebanon": 5.375,
    "Jordan": 5.425,
    "Trinidad and Tobago": 5.525,
    "Chile": 5.95,
    "Ecuador": 6.25,
    "Sudan": 6.475,
    "Argentina": 6.525,
    "Costa Rica": 6.725,
    "Uruguay": 6.825,
    "Latvia": 7.325,
    "Romania": 7.4,
    "Palau": 7.5,
    "Antigua and Barbuda": 7.6,
    "Barbados": 7.825,
    "Croatia": 8.175,
    "Saint Kitts and Nevis": 8.325,
    "Hungary": 8.45,
    "Czech Republic": 9.325,
    "Estonia": 9.475,
    "Saudi Arabia": 9.625,
    "Poland": 9.925,
    "Lithuania": 10,
    "Turkey": 10.325,
    "Slovakia": 10.45,
    "Oman": 10.975,
    "Portugal": 11.9,
    "Hong Kong": 12.075,
    "Greece": 12.9,
    "The Bahamas": 13.125,
    "Taiwan": 13.15,
    "Malta": 13.25,
    "Slovenia": 15.075,
    "Andorra": 17.125,
    "United States": 18.125,
    "Japan": 18.175,
    "Spain": 18.85,
    "Israel": 20.225,
    "South Korea": 21.5,
    "San Marino": 25.95,
    "Canada": 26.35,
    "Germany": 27.075,
    "Netherlands": 27.5,
    "United Kingdom": 27.95,
    "Belgium": 28.55,
    "France": 29.05,
    "Monaco": 29.7,
    "Ireland": 29.8,
    "New Zealand": 33.5,
    "Luxembourg": 35.3,
    "Australia": 38.15,
    "Switzerland": 28.55,
    "Austria": 28.55,
    "Bahrain": 10.975,
    "Brunei": 4,
    "Burundi": 4,
    "Cambodia": 4,
    "Cyprus": 10.975,
    "Denmark": 28.55,
    "Djibouti": 1,
    "Egypt": 5.275,
    "Eritrea": 4,
    "Ethiopia": 5.275,
    "Federated States of Micronesia": 4,
    "Finland": 28.55,
    "Grenada": 4,
    "Guernsey": 4,
    "Guinea": 4,
    "Iceland": 28.55,
    "Isle of Man": 4,
    "Italy": 28.55,
    "Jersey": 4,
    "Liechtenstein": 28.55,
    "Maldives": 4,
    "Namibia": 4,
    "Nauru": 4,
    "Northern Cyprus": 10.975,
    "Norway": 28.55,
    "Qatar": 28.55,
    "Rwanda": 4,
    "Saint Lucia": 4,
    "São Tomé and Príncipe": 4,
    "Singapore": 28.55,
    "Somalia": 4,
    "South Sudan": 4,
    "Suriname": 4,
    "Sweden": 28.55,
    "Tonga": 4,
    "Tuvalu": 4,
    "United Arab Emirates": 4,
    "Venezuela": 4,
    "Yemen": 4,
    "Zimbabwe": 4
}

export { wages }