const prices = {
    "Classification": 20,
    "Point Annotation": 10,
    "Line Annotation": 15,
    "2D Box Annotation": 25,
    "3D Box Annotation": 35,
    "Polygon Annotation": 50,
    "Lidar 3D Box Annotation": 60,
    "Lidar Pixelwise Annotation": 200,
}

function calculateClassPrices(classPrice) {
    var classPriceValue = 3 * classPrice * 0.1
    return classPriceValue
}


function calculateAccuracyPrice(accuracy) {
    var accuracyPrice = (1 + ((accuracy - 80) ** 1.2 * 0.01)).toFixed(3)
    return accuracyPrice

}

function calculateImageDiscount(imageNumber) {
    var imageDiscountValue = Math.max((1 - (imageNumber * 0.000003)).toFixed(2), 0.5)
    return imageDiscountValue
}

export {
    prices,
    calculateImageDiscount,
    calculateClassPrices,
    calculateAccuracyPrice

}