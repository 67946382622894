import { createMuiTheme } from '@material-ui/core/styles';

const font = "'Montserrat', sans-serif";

const globalTheme = createMuiTheme({
    palette: {
        primary: {
            light: "#5b69c4",
            main: "#233f93",
            dark: "#001a6",
            background: {
                default: "#FFFFFF"
            },
        },
        secondary: {
            light: "#7b8ec4",
            main: "#4c6193",
            dark: "#1c3765",
            background: {
                default: "#FFFFFF"
            },
        }
    },
    typography: {
        fontFamily: font
    }
})

export { globalTheme }